<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <Toolbar class="mb-4">
                    <template v-slot:start>
                 
                        <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
                    </template>
                </Toolbar>

                <DataTable ref="dt" exportFilename="relatorio[BASE1]" csvSeparator=";" :value="empresas"
                    v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="50" :filters="filters"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                    responsiveLayout="scroll">
                    <template #header>
                        <div class="
                  flex flex-column
                  md:flex-row md:justify-content-between md:align-items-center
                ">
                            <h5 class="m-0">Dados Consolidados - Compartilhamento</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>

                    <Column v-for="col of cabecalho" :field="col.field" :header="col.header" :key="col.field"
                        style="white-space:nowrap">

                    </Column>
                </DataTable>

                <Dialog v-model:visible="productDialog" :style="{ width: '450px' }" header="Dados Importados - Base 1"
                    :modal="true" class="p-fluid">
                    <div class="field">
                        <label for="razao_social">Razão Social</label>
                        <InputText id="razao_social" v-model.trim="empresa.razao_social" required="true" autofocus
                            :class="{ 'p-invalid': submitted && !empresa.razao_social }" />
                        <small class="p-invalid" v-if="submitted && !empresa.razao_social">Razão Social é
                            obrigatório.</small>
                    </div>
                    <div class="field">
                        <label for="name">CNPJ</label>
                        <InputMask id="name" v-model.trim="empresa.cnpj" required="true" autofocus mask="99.999.999/9999-99"
                            :class="{ 'p-invalid': submitted && !empresa.cnpj }" />
                        <small class="p-invalid" v-if="submitted && !empresa.cnpj">CNPJ é obrigatório.</small>
                    </div>
                    <div class="field">
                        <label for="email">E-mail</label>
                        <InputText id="email" type="email" v-model.trim="empresa.email" required="true" autofocus
                            :class="{ 'p-invalid': submitted && !empresa.email }" />
                    </div>
                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm"
                            @click="hideDialog" />
                        <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm"
                            @click="salvarEmpresa" />
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true" header="&nbsp;">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem;display:block;" />
                        <span style="display:block;" v-if="empresa">Apagar: <b>{{ empresa.razao_social }}</b>?</span>
                    </div>
                    <template #footer class="align-items-center ">
                        <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm"
                            @click="deleteProductDialog = false" />
                        <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarEmpresa" />
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span v-if="empresas">Apagar empresas selecionadas?</span>
                    </div>
                    <template #footer>
                        <Button label="Não" icon="pi pi-times" class="p-button-text"
                            @click="deleteProductsDialog = false" />
                        <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>
  
<script>
import { FilterMatchMode } from "primevue/api";

export default {
    data() {
        return {
            cabecalho: [
                { header: "Site ID TIM", field: 'site_id_tim' },
                { header: "END ID TIM", field: 'endereco_id_tim' },
                { header: "Regional TIM", field: 'regional_tim' },
                { header: "UF", field: 'uf' },
                { header: "Detentora ", field: 'detentora' },
                { header: "Site ID Detentora", field: 'site_id_detentora' },
                { header: "Data RFI", field: 'data_rfi' },
                { header: "Tipo de Energia", field: 'tipo_energia' },
                { header: "Consumo (KWh)", field: 'consumo' },
                { header: "É viável Instalação de Medidor TIM?", field: 'viavel_instalacao_medidor_tim' },
                { header: "Justificativa", field: 'justificativa' },
                { header: "Mês de Referência do Formulário", field: 'mes_referencia_formulario' },
                { header: "Data de envio do formulário", field: 'data_envio_formulario' },
                { header: "Mês de Referência da Fatura", field: 'mes_referencia_fatura' },
                { header: "Valor Total Fatura (R$) ", field: 'valor_total_fatura' },
                { header: "Concatenado (END_ID + Dententora)", field: 'concatenado' },
                
                //Base Xerox
                { header: "Status Base Cadastral", field: 'status_compartilhamento' },
                { header: "Status SGE", field: 'status_sge' },
                { header: "Data Entrante SGE", field: 'data_cadastro' },
                { header: "Status Titularidade", field: 'status_workflow' },
                { header: "Status Site OPM", field: 'status_opm' },
                { header: "ETAPA ATUAL NETFLOW", field: 'status_netflow' },
                
                //regras
                { header: "Classificação do Reembolso", field: 'classificao_reembolso' },
                { header: "Motivo da Contestação", field: 'contestacao_reembolso' },
                { header: "Valor Contestado_(R$)", field: 'classificacao_final_reembolso' },
                { header: "Valor Validado_(R$)", field: 'data_nao_definida' },
                { header: "Status", field: 'status_contestacao' },
                { header: "Data da validação", field: 'data_entrante_sge_ajustada' },
            ],
            empresas: [],
            productDialog: false,
            deleteProductDialog: false,
            deleteProductsDialog: false,
            empresa: {},
            selectedProducts: null,
            filters: {},
            submitted: false,
            status: 0,
            statuses: [
                { label: "Inativo", value: "0" },
                { label: "Ativo", value: "1" },
            ],
        };
    },
    productService: null,
    created() {
        this.initFilters();
    },
    mounted() {
        this.listarRelatorio();
        //this.productService.getProducts().then((data) => (this.products = data));
    },
    methods: {
        listarRelatorio() {
            this.axios
                .post("/relatorios/consolidado")
                .then((response) => {
                    console.log(response);
                    this.empresas = response.data.dados;
                })
                .catch(() => {
                    self.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },

        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.products.length; i++) {
                if (this.products[i].id === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        createId() {
            let id = "";
            var chars =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 5; i++) {
                id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return id;
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },

        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
    },
};
</script>
  
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
  